import { Routes, Route } from 'react-router-dom';
import { Error } from './error';
import { routes } from './routes';
import { Home } from './home'
const Router = (): JSX.Element => {
	return (
		<Routes>
			{routes.map(({ path  }) => (
				<Route key={path} path={path} element={<Home />} />
			))}
			<Route element={Error} />
		</Routes>
	);
};

export default Router;
