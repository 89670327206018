import { Home } from "./home";
const HomePage = () => Home;

interface RoutesProps {
	path: string;
	exact: boolean;
	element: any;
	name: string;
	mainRoutes: boolean;
	authRoutes: boolean;
	status: boolean;
}

export const routes: RoutesProps[] = [
	{
		path: '/',
		exact: true,
		element: HomePage,
		name: 'Home',
		mainRoutes: true,
		authRoutes: false,
		status: false,
	},
];
